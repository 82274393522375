<template>
    <div class="flex min-h-screen bg-white">
        <div class="flex flex-col flex-1 justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div class="mx-auto w-full max-w-sm lg:w-96">
                <img class="mx-auto mb-8" src="./../assets/logo2025.png" alt="MyOutDesk, LLC">
                <div class="block flex-grow w-full rounded-md">
                    <p class="mt-2 mb-8 text-sm leading-5 text-center text-gray-600 max-w">
                        Thank you for choosing MyOutDesk as your Virtual Assistant Company.
                    </p>
                    <button id="register" type="submit" class="flex justify-center px-4 py-2 w-full text-sm font-medium text-white rounded-md border border-transparent transition duration-150 ease-in-out bg-mod-500 hover:bg-mod-400 focus:border-mod-700 focus:shadow-outline-orange" @click.stop.prevent="$emit('next')">
                        <span v-if="step === 1">Request Addon</span>
                        <span v-else>Continue Addon Request</span>
                    </button>
                </div>
                <button v-if="step > 1" id="reset_button" class="flex justify-center px-4 py-2 mt-2 w-full text-sm font-medium text-white text-red-500 rounded-md border border-red-500 transition duration-150 ease-in-out focus:border-red-700 focus:shadow-outline-red" @click="resetPrompt = true">
                    Start Over
                </button>
            </div>
        </div>
        <div class="hidden relative flex-1 w-0 lg:block">
            <img class="object-cover absolute inset-0 w-full h-full" src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80" alt="">
        </div>
        <Alert :show="resetPrompt" @reset="reset" @cancel="resetPrompt = false"/>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import Alert from "@/components/Alert";
    export default {
        name: 'Introduction',
        components: {Alert},
        data(){
            return{
                resetPrompt: false,
            }
        },
        computed:{
            ...mapGetters([
                "step"
            ])
        },
        methods:{
            ...mapActions([
                'resetState'
            ]),
            reset(){
                this.resetState();
                this.$cookie.delete('mod_form_0');
                this.$cookie.delete('mod_form_1');
                this.$cookie.delete('mod_form_2');
                this.resetPrompt = false;
            }
        }
    }
</script>
